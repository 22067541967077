import './App.css';
import AboutUs from './components/AboutUs';
import Home from './components/Home'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Clients from './components/Clients';
import Allies from './components/Allies';
import Contact from './components/Contact';
import Cookies from './components/Cookies';
import Privacy from './components/Privacy';
import PersonalData from './components/PersonalData';
import OurAllies from './components/OurAllies';
import FAQClients from './components/FAQClients';
import FAQAllies from './components/FAQAllies';
import Rates from './components/Rates';
import PaymentResult from './components/PaymentResult';
import Payment from './components/payment';
import PaymentResultPSE from './components/PaymentResultPSE';
import PaymentResultNequi from './components/PaymentResultNequi';

function App() {

  const goToChan = () => {
    window.open(process.env.REACT_APP_URL_CHAN, "_blank", "noreferrer");

  }

  const goToAgora = () => {
    window.open(process.env.REACT_APP_URL_AGORA, "_blank", "noreferrer");

  }


  return (
    <>


      <Router>


        <Routes>
          <Route path="/" element={<Header goToChan={goToChan} goToAgora={goToAgora} />} >
            <Route path="/" element={<Home goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="aboutus" element={<AboutUs goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="clients" element={<Clients goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="allies" element={<Allies goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="contact" element={<Contact goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="cookies" element={<Cookies goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="privacy" element={<Privacy goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="personal" element={<PersonalData goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="ourallies" element={<OurAllies goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="faqclients" element={<FAQClients goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="faqallies" element={<FAQAllies goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="rates" element={<Rates goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="paymentResult" element={<PaymentResult goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="paymentResultPSE" element={<PaymentResultPSE goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="paymentResultNequi" element={<PaymentResultNequi goToChan={goToChan} goToAgora={goToAgora} />} />
            <Route path="payment" element={<Payment goToChan={goToChan} />} />
            
          </Route>


        </Routes>
      </Router>
    </>



  );
}

export default App;
