import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useCookies } from 'react-cookie';
import { Col, Row } from "react-bootstrap";

const Header = ({ goToChan, goToAgora }) => {

    const [sidebarOpen1, setSidebarOpen1] = useState(false);
    const [sidebarOpen2, setSidebarOpen2] = useState(false);
    const [cookies, setCookies] = useCookies();
    const [visibilityCookies, setVisibilityCookies] = useState(!cookies.acceptCookies ? "initial" : cookies.acceptCookies == "1" ? "none" : "initial");

    const navigate = useNavigate();


    const openNav1 = () => {
        setSidebarOpen1(true);
    }

    const closeNav1 = () => {
        setSidebarOpen1(false);
    }

    const openNav2 = () => {
        setSidebarOpen2(true);
    }

    const closeNav2 = () => {
        setSidebarOpen2(false);
    }

    const goToIndex = () => {
        navigate("/");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }

    const goToAboutUs = () => {
        navigate("/aboutus");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }

    const goToClients = () => {
        navigate("/clients");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }

    const goToAllies = () => {
        navigate("/allies");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }

    const goToContact = () => {
        navigate("/contact");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }

    const goToCookies = () => {
        navigate("/cookies");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }


    const goToPrivacy = () => {
        navigate("/privacy");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }

    const goToPersonal = () => {
        navigate("/personal");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }

    const acceptCookies = () => {
        setVisibilityCookies("none");
        setCookies("acceptCookies", "1", { expires: new Date("2100-01-01") })
    }

    const goToOurAllies = () => {
        navigate("/ourallies");
        window.scrollTo(0, 0);
        setSidebarOpen1(false);
    }

    // const goToWompi = () => {
    //     window.open("https://checkout.wompi.co/l/VV3GcV", "_blank", "noreferrer")

    // }

    const goToRates = () => {
        navigate("/rates");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }

    const goToPayment = () => {
        navigate("/payment");
        window.scrollTo(0, 0)
        setSidebarOpen1(false);
    }

    return (<>
        {/* 
        
        <div id="mySidebar1" className={`sidebar ${sidebarOpen1 ? "open" : ""}`} >

            <div className="d-flex justify-content-end align-items-center me-4 pt-5">

                <button className="text-end btn-transparent" onClick={closeNav1} >
                    <img className="img-fluid w-75" src="../assets/images/icons/close.svg" alt="menu" />
                </button>
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center h-75">
                <div className="d-flex flex-column align-items-baseline justify-content-center">
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToAboutUs}>Quienés somos</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToClients}>Para pacientes</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToAllies}>Para aliados</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToContact}>Contactos</button>
                </div>
            </div>
        </div>

        <div id="mySidebar2" className={`sidebar ${sidebarOpen2 ? "open" : ""}`} >

            <div className="d-flex justify-content-end align-items-center me-4 pt-5">

                <button className="text-end btn-transparent" onClick={closeNav2} >
                    <img className="img-fluid w-75" src="../assets/images/icons/close.svg" alt="menu" />
                </button>
            </div>

            <div
                className="d-flex flex-column align-items-center justify-content-center h-75"
            >
                <div className="d-flex flex-column align-items-baseline justify-content-center">

                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToChan}>Log in pacientes</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToAgora}>Log in aliados</button>

                </div>
            </div>
        </div>

        <nav className="navbar py-2 mx-4" style={{height:"15vh"}}>
            <div className="container-fluid">
                <button className="navbar-brand btn-transparent" onClick={goToIndex} >
                    <img src="assets/images/icons/meddipay-logo.svg" alt="Meddipay" className="imgLogo" />
                </button>
                <div>
                    <button className="text-end me-4 text-decoration-none btn-transparent" onClick={openNav2} >
                        <img src="assets/images/icons/user-icon.svg" alt="menu" />
                    </button>
                    <button className="text-end btn-transparent" onClick={openNav1} >
                        <img src="assets/images/icons/hamburguer.svg" alt="menu" />
                    </button>
                </div>
            </div>
        </nav>




        <Outlet />



        <footer className="bg-mp-primary text-white py-3">
            <div className="container d-flex justify-content-center">
                <div className="row w-100 py-5">
                    <div className="col-sm-3 py-2">
                        <img src="assets/images/img/meddipay-white-logo.svg" alt="" />
                    </div>
                    <div className="col-sm-3 fs-5 fw-bold d-flex flex-column">
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToIndex}>Home</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToAboutUs}>Quíenes somos</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToPrivacy}>Protección de datos</button>
                    </div>
                    <div className="col-sm-3 fs-5 fw-bold d-flex flex-column">
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToClients} >Para pacientes</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToAllies}>Para aliados</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToPersonal}>Aviso legal</button>
                    </div>
                    <div className="col-sm-3 fs-5 fw-bold d-flex flex-column">
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToContact}>Contacto</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToCookies}>Política de cookies</button>
                    </div>
                </div>
            </div>
            <div className="container d-flex justify-content-center">
                <div className="row w-100 py-2">
                    <div className="col-sm-3 fw-bold d-flex flex-column">
                        <button className="meddipay-ce-text-color text-decoration-none pb-4 btn-transparent">{new Date().getFullYear()} MEDDIPAY SAS. Todos los derechos reservados</button>
                    </div>
                    <div className="col-sm-3 fw-bold d-flex">
                        <button className="px-2 btn-transparent">
                            <img
                                className="img-fluid"
                                src="assets/images/img/facebook.svg"
                                alt=""
                            />
                        </button>
                        <button className="px-2 btn-transparent" >
                            <img
                                className="img-fluid"
                                src="assets/images/img/instagram.svg"
                                alt=""
                            />
                        </button>
                        <button className="px-2 btn-transparent">
                            <img
                                className="img-fluid"
                                src="assets/images/img/linkedin.svg"
                                alt=""
                            />
                        </button>
                    </div>
                </div>
            </div>
        </footer> */}

        <div id="mySidebar1" className={`sidebar ${sidebarOpen1 ? "open" : ""}`} >

            <div className="d-flex justify-content-end align-items-center me-4 pt-5">

                <button className="text-end btn-transparent" onClick={closeNav1} >
                    <img className="img-fluid w-75" src="../assets/images/icons/close.svg" alt="menu" />
                </button>
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center h-75">
                <div className="d-flex flex-column align-items-baseline justify-content-center">
                    <button className="fw-bold" onClick={goToPayment} style={{ marginBottom: "30px", color: "#00a9eb", backgroundColor: "white", border: "none", borderRadius: "20px", width: "100%", fontSize: "22px", height: "50px" }}>Paga tu cuota</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToAboutUs}>Quiénes somos</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToClients}>Para pacientes</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToAllies}>Para aliados</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToOurAllies}>Nuestros aliados</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" onClick={goToContact}>Contacto</button>
                </div>
            </div>
        </div>

        <div id="mySidebar2" className={`sidebar ${sidebarOpen2 ? "open" : ""}`} >

            <div className="d-flex justify-content-end align-items-center me-4 pt-5">

                <button className="text-end btn-transparent" onClick={closeNav2} >
                    <img className="img-fluid w-75" src="../assets/images/icons/close.svg" alt="menu" />
                </button>
            </div>

            <div
                className="d-flex flex-column align-items-center justify-content-center h-75"
            >
                <div className="d-flex flex-column align-items-baseline justify-content-center">

                    <button className="fs-1 fw-bold text-white btn-transparent" style={{ display: "flex", alignItems: "center" }} onClick={goToChan}><img style={{ width: "30px" }} src="../assets/images/img/FaRegularSmileWink.svg" alt="pacientes" /> Acceso pacientes</button>
                    <button className="fs-1 fw-bold text-white btn-transparent" style={{ display: "flex", alignItems: "center" }} onClick={goToAgora}><img style={{ width: "30px" }} src="../assets/images/img/MapDoctor.svg" alt="aliados" /> Portal aliados</button>

                </div>
            </div>
        </div>

        <Navbar >
            <Container style={{ minHeight: "15vh", paddingLeft: "5px" }}>
                <Navbar.Brand>
                    <button className="navbar-brand btn-transparent" onClick={goToIndex} >
                        <img src="assets/images/icons/meddipay-logo.svg" alt="Meddipay" className="imgLogo" />
                    </button>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <button className="text-end me-4 text-decoration-none btn-transparent" onClick={openNav2} >
                        <img src="assets/images/icons/user-icon.svg" alt="menu" />
                    </button>
                    <button className="text-end btn-transparent" onClick={openNav1} >
                        <img src="assets/images/icons/hamburguer.svg" alt="menu" />
                    </button>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Outlet />



        <footer className="bg-mp-primary text-white py-3">

            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", marginBottom: "20px" }}>
                        <img src="assets/images/img/meddipay-blue-logo.svg" alt="" style={{ width: "50px" }} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center" }}>
                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToIndex}>Inicio</button>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center", fontWeight: "bold" }}>

                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToPayment}>Paga tu cuota</button>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center" }}>

                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToAboutUs}>Quiénes somos</button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center" }}>
                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToOurAllies}>Nuestros aliados</button>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center", fontWeight: "bold" }}>

                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToClients} >Para pacientes</button>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center" }}>

                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToAllies}>Para aliados</button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center" }}>
                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToPrivacy}>Protección de datos</button>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center", fontWeight: "bold" }}>

                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToPersonal} >Aviso legal</button>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center" }}>

                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToCookies}>Política de cookies</button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center" }}>
                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToRates}>Tasas y tarifas</button>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center", fontWeight: "bold" }}>

                        <button className="text-white text-decoration-none py-2 btn-transparent" style={{ fontWeight: "bold" }} onClick={goToContact} >Contacto</button>

                    </Col>


                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center", fontWeight: "bold" }}>


                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center", fontWeight: "bold" }}>


                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: "center", fontWeight: "bold" }}>

                        <img style={{ marginTop: "10px", width: "150px", cursor: "pointer" }} onClick={() => { window.open("https://www.sic.gov.co/", "_blank", "noreferrer") }}
                            src="assets/images/img/sic.jpg"
                            alt="payment" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", marginTop: "20px" }}>
                        <button className="meddipay-ce-text-color text-decoration-none pb-4 btn-transparent">© {new Date().getFullYear()} MEDDIPAY SAS. Todos los derechos reservados</button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                        <button className="px-2 btn-transparent" onClick={() => { window.open("https://www.facebook.com/profile.php?id=100093624723231", "_blank", "noreferrer") }}>
                            <img
                                className="img-fluid"
                                src="assets/images/img/facebook.svg"
                                alt=""
                            />
                        </button>
                        <button className="px-2 btn-transparent" onClick={() => { window.open("https://www.instagram.com/meddipay.colombia/?fbclid=IwAR0MtuqYUmMTs4ZoWH3b7m_H_Chw-9KRsLwao-GwLIlfZMqQFAapPse0Gik", "_blank", "noreferrer") }}>
                            <img
                                className="img-fluid"
                                src="assets/images/img/instagram.svg"
                                alt=""
                            />
                        </button>

                    </Col>
                </Row>
            </Container>

            {/* <div className="container d-flex justify-content-center">
                <div className="row w-100 py-5">
                    <div className="col-sm-3 py-2" style={{ textAlign: "center" }}>
                        <img src="assets/images/img/meddipay-blue-logo.svg" alt="" style={{ width: "50px" }} />
                    </div>
                    <div className="col-sm-3 fs-5 fw-bold d-flex flex-column">
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToIndex}>Home</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToAboutUs}>Quíenes somos</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToPrivacy}>Protección de datos</button>
                    </div>

                </div>
                <div className="row w-100 py-5">
                    <div className="col-sm-3 fs-5 fw-bold d-flex flex-column">
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToClients} >Para pacientes</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToAllies}>Para aliados</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToPersonal}>Aviso legal</button>
                    </div>

                </div>
                <div className="row w-100 py-5">
                    <div className="col-sm-3 fs-5 fw-bold d-flex flex-column">
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToContact}>Contacto</button>
                        <button className="text-white text-decoration-none py-2 btn-transparent" onClick={goToCookies}>Política de cookies</button>
                    </div>
                </div>
            </div> */}

            {/* <div className="container d-flex justify-content-center">
                <div className="row w-100 py-2">
                    <div className="col-sm-3 fw-bold d-flex flex-column">
                        <button className="meddipay-ce-text-color text-decoration-none pb-4 btn-transparent">{new Date().getFullYear()} MEDDIPAY SAS. Todos los derechos reservados</button>
                    </div>
                    <div className="col-sm-3 fw-bold d-flex">
                        <button className="px-2 btn-transparent">
                            <img
                                className="img-fluid"
                                src="assets/images/img/facebook.svg"
                                alt=""
                            />
                        </button>
                        <button className="px-2 btn-transparent" >
                            <img
                                className="img-fluid"
                                src="assets/images/img/instagram.svg"
                                alt=""
                            />
                        </button>
                        <button className="px-2 btn-transparent">
                            <img
                                className="img-fluid"
                                src="assets/images/img/linkedin.svg"
                                alt=""
                            />
                        </button>
                    </div>
                </div>
            </div> */}
        </footer>


        <div className="cookie-consent-banner" style={{ display: visibilityCookies }}>
            <div className="cookie-consent-banner__inner">
                <div className="cookie-consent-banner__copy">
                    <div className="cookie-consent-banner__header" style={{ padding: "13px" }}>Aviso de aceptación de cookies</div>
                    <div className="cookie-consent-banner__description" style={{ padding: "13px" }}>Su privacidad es importante para nosotros. Este sitio web usa cookies en su dispositivo, las cuales son utilizadas para almacenar la información de su interacción con la página web, para fines analíticos y de optimización de la web. Al dar clic en aceptar, usted acepta nuestra política de cookies. Para conocer más acerca de las cookies, <u style={{ color: "blue", cursor: "pointer" }} onClick={goToCookies}>consulta nuestra política de cookies</u>
                    </div>
                </div>

                <div className="cookie-consent-banner__actions" style={{ padding: "13px" }}>
                    <a className="cookie-consent-banner__cta" onClick={acceptCookies} style={{ cursor: "pointer" }}>Aceptar
                    </a>


                </div>
            </div>
        </div>


    </>)
}

export default Header;